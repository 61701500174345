type EnvironmentOption = "production" | "staging" | "local"
interface useEnvironmentValue {
  env: EnvironmentOption
  isProduction: boolean
  isStaging: boolean
  isLocal: boolean
}
const useEnvironment = (): useEnvironmentValue => {
  let env: EnvironmentOption

  switch (location.hostname) {
    case "peralta.app":
    case "peralta.netlify.app":
      env = "production"
      break
    case "peralta-staging.netlify.app":
      env = "staging"
      break
    default:
      env = "local"
  }

  const isProduction = env === "production"
  const isStaging = env === "staging"
  const isLocal = env === "local"

  return {
    env,
    isProduction,
    isStaging,
    isLocal,
  }
}

export { useEnvironment }
