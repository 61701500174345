import AlerterMixin from "@/mixins/Alerter"
import store from "@/store"

const alerterInstance = new AlerterMixin()
alerterInstance.$store = store

type Notifications = {
  addError: (message: string) => void
  addSuccess: (message: string) => void
  addInfo: (message: string) => void
  addWarning: (message: string) => void
  addGraphQLError: (error: Error) => void
  addMutationError: (error: Error) => void
}

const useNotification = (): Notifications => {
  const addError = (message: string) => {
    alerterInstance.addError(message)
  }

  const addSuccess = (message: string) => {
    alerterInstance.addSuccess(message)
  }

  const addInfo = (message: string) => {
    alerterInstance.addInfo(message)
  }

  const addWarning = (message: string) => {
    alerterInstance.addWarning(message)
  }

  const addGraphQLError = (error: Error) => {
    alerterInstance.addGraphQLError(error)
  }

  const addMutationError = (error: Error) => {
    alerterInstance.addMutationError(error)
  }

  return {
    addError,
    addSuccess,
    addInfo,
    addWarning,
    addGraphQLError,
    addMutationError,
  }
}

export { useNotification }
