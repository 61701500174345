
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class TagChip extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ default: false }) closable!: boolean
  @Prop({ default: false }) outline!: boolean

  get attrs() {
    const { label, ...attrs } = this.$attrs
    return attrs
  }

  get textColor() {
    return this.outline ? "#8c8c8c" : "black-text"
  }

  close() {
    //Remove chip
    this.$emit("close")
  }
}
