export type Routes = {
  ResetPassword: string
  ForgotPassword: string
  ConfirmEmail: string
  Roles: string
  Apply: string
  Home: string
  Clients: string
  ClientDetail: string
  Missions: string
  MissionDashboard: string
  TalentDashboard: string
  TalentPool: string
  TalentApplications: string
  TalentAppraisals: string
  TalentPerformanceAppraisals: string
  TalentPeralteePoints: string
  SurveyDetail: string
  Surveys: string
  ScopeLibrary: string
  ScopeLibraryTreeView: string
  ScopeLibraryTags: string
  CompleteSurvey: string
  SurveyDialog: string
  ConsultantDetail: string
  TalentApplicantsConsultantDetail: string
  Users: string
  UserDetail: string
  MissionDetail: string
  PrivacyPolicy: string
  CookiePolicy: string
  TermsAndConditions: string
  Profile: string
  EditProfile: string
  Tasks: string
  Appraisals: string
  MyPerformanceAppraisals: string
  ConsultantBasic: string
  MyPeraltees: string
  UserRoles: string
  UserRoleDetail: string
  Permissions: string
  MissionRoleDetail: string
  AppraisalSchedule: string
  ContractDetail: string
  InvoiceDetail: string
  Invoices: string
  Login: string
  BecomeAClient: string
  BillingAccounts: string
  Opportunities: string
  MyMissions: string
  ConsultantContracts: string
  TalentApplicationsSplitView: string
  ApplicantsManager: string
  BrandResources: string
  ConsultantContractAdmin: string
  Projects: string
  ClientTasks: string
  ClientBilling: string
  ClientProjectDetails: string
  Invitation: string
  Settings: string
  Ahead: string
  ResetApplicantPassword: string
  AheadClientSignup: string
  AheadClientSignupSuccess: string
  AheadClientHome: string
  Cookies: string
}

const routes: Routes = {
  ResetPassword: "reset-password",
  ResetApplicantPassword: "reset-applicant-password",
  ForgotPassword: "forgot-password",
  ConfirmEmail: "confirm-email",
  Roles: "roles",
  Apply: "apply",
  Home: "home",
  Cookies: "cookies",
  Clients: "clients",
  ClientDetail: "client-detail",
  Missions: "missions",
  MissionDashboard: "mission-dashboard",
  TalentDashboard: "talent-dashboard",
  TalentPool: "talent-pool",
  TalentApplications: "applications",
  TalentAppraisals: "talent-appraisals",
  TalentPerformanceAppraisals: "talent-performance-appraisals",
  TalentPeralteePoints: "talent-peraltees",
  AppraisalSchedule: "talent-appraisal-schedule",
  SurveyDetail: "survey-detail",
  Surveys: "surveys",
  CompleteSurvey: "complete-survey",
  SurveyDialog: "survey-dialog",
  ScopeLibrary: "scope-library",
  ScopeLibraryTags: "scope-library-tags",
  ScopeLibraryTreeView: "scope-library-tree-view",
  ConsultantDetail: "consultant-detail",
  TalentApplicantsConsultantDetail: "talent-applicants-consultant-detail",
  Users: "users",
  UserDetail: "user-detail",
  MissionDetail: "mission-detail2",
  PrivacyPolicy: "privacy-policy",
  CookiePolicy: "cookie-policy",
  TermsAndConditions: "terms",
  Profile: "profile",
  EditProfile: "edit-profile",
  Tasks: "tasks",
  Appraisals: "appraisals",
  MyPerformanceAppraisals: "my-performance-appraisals",
  MyPeraltees: "my-peraltees",
  ConsultantBasic: "consultant-basic",
  UserRoles: "user-roles",
  UserRoleDetail: "user-roles-detail",
  Permissions: "permissions",
  MissionRoleDetail: "mission-role-detail",
  ContractDetail: "contract-detail",
  InvoiceDetail: "invoice-detail",
  Invoices: "invoices",
  Login: "login",
  BecomeAClient: "become-a-client",
  BillingAccounts: "billing-accounts",
  Opportunities: "opportunities",
  MyMissions: "my-missions",
  ConsultantContracts: "consultant-contract",
  TalentApplicationsSplitView: "talent-applications-list-view",
  ApplicantsManager: "applications-manager",
  BrandResources: "brand-resources",
  ConsultantContractAdmin: "consultant-contract-admin",
  Projects: "projects",
  ClientTasks: "tasks",
  ClientBilling: "billing",
  ClientProjectDetails: "project-details",
  Invitation: "invitation",
  Settings: "settings",
  Ahead: "ahead-admin",
  AheadClientSignup: "sign-up-ahead",
  AheadClientSignupSuccess: "sign-up-ahead-success",
  AheadClientHome: "billing-ahead",
}

export default routes
