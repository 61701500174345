
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Icon from "@/components/widgets/icons/Icon.vue"

@Component({
  components: {
    EditorContent,
    BubbleMenu,
    Icon,
  },
})
export default class Tiptap extends Vue {
  editor: any = null
  @Prop({ default: "" }) readonly content!: string
  @Prop({ default: "" }) readonly value?: string

  mounted() {
    this.editor = new Editor({
      content: (this.$refs.content as HTMLElement).innerHTML,
      extensions: [StarterKit],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML())
      },
    })
    ;((this.$refs.editor as Vue).$el as HTMLElement).addEventListener("keydown", this.handleTab)
  }

  @Watch("value")
  onValueChange() {
    // HTML
    const isSame = this.editor.getHTML() === this.value

    if (isSame) {
      return
    }

    this.editor.commands.setContent(this.value, false)
  }

  beforeDestroy() {
    this.editor.destroy()
    ;((this.$refs.editor as Vue).$el as HTMLElement).removeEventListener("keydown", this.handleTab)
  }

  handleTab(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      return this.editor.commands.insertContent("\t")
    }
  }
}
