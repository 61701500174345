
import { Component, Vue } from "vue-property-decorator"
import WorldConsultantsMapData from "./worldConsultantsMapData.json"

interface IMapData {
  firstName: string
  role: string
  industry: string
  country: string
  image: string
  position: {
    top: number
    left: number
  }
}

@Component
export default class WorldConsultantsMap extends Vue {
  showTooltip: Record<number, boolean> = {}
  currentDisplayedTooltip = 0
  consultantMapWidth: number | null = null
  consultantMapHeight: number | null = null

  mapData: IMapData[] = WorldConsultantsMapData?.consultants
  mounted() {
    this.handleAnimatedTooltipdisplay()
  }

  // display a new tooltip every x seconds
  handleAnimatedTooltipdisplay() {
    const lengthOfConsultants = this.mapData.length
    setInterval(() => {
      this.currentDisplayedTooltip++
      this.showTooltip = { [this.currentDisplayedTooltip]: true }
      if (this.currentDisplayedTooltip === lengthOfConsultants) {
        this.currentDisplayedTooltip = 0
      }
    }, 3000)
  }

  // needed for the non-animated tooltip markers
  shouldTooltipOpen(toolTipIndex: number) {
    return this.currentDisplayedTooltip !== toolTipIndex
  }
  displayCurrentAnimatedTooltip(mapItemIndex: number) {
    return mapItemIndex === this.currentDisplayedTooltip
  }
}
