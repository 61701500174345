
import { Namespaces } from "@/constants"
import { Action } from "vuex-class"
import { Component, Vue } from "vue-property-decorator"
import CoreTeamNav from "@/components/layout/CoreTeamNav.vue"
import ConsultantNav from "@/components/layout/ConsultantNav.vue"
import Nav from "@/components/layout/Nav.vue"
import LandingPage from "@/components/auth/LandingPage/LandingPage.vue"
import Auth from "@/views/Auth.vue"
import AppHeader from "@/components/layout/AppHeader.vue"
import { useEnvironment } from "@/hooks/useEnvironment"
import ClientNav from "@/components/layout/ClientNav.vue"
import { useAccountContext } from "@/hooks/useAccountContext"
import { MeUserQuery, MeUserQueryQuery, ProductSlugEnum } from "@/gql"
import { useQuery } from "@vue/apollo-composable"
import { UpdateSessionPayload } from "@/store/auth"
import { useAuth } from "@/store/auth/useAuth"

const namespace = Namespaces.Auth

@Component({
  components: { Auth, ConsultantNav, Nav, CoreTeamNav, AppHeader, LandingPage, ClientNav },
})
export default class Index extends Vue {
  @Action("endSession", { namespace }) endSession!: () => void
  @Action("clearAll") clearAllStore!: () => void

  @Action("updateSession", { namespace: Namespaces.Auth })
  updateSession!: (payload: UpdateSessionPayload) => void

  account = useAccountContext()

  get isConsultantUser() {
    return this.account.isConsultant
  }

  // TODO: refactor to use User.product
  get logoUrl() {
    switch (this.account.clientProduct) {
      case ProductSlugEnum.AheadBusiness:
        return "/ahead_logo.svg"

      case ProductSlugEnum.Peralta:
        return "/logo_platform.svg"

      default:
        return "/logo_platform.svg"
    }
  }

  get isClientUser() {
    return this.account.isClient
  }

  get isCoreUser() {
    return this.account.isCoreTeam
  }

  get isHomeRoute() {
    return this.$route.name === this.$routes.Home
  }

  get env() {
    const { env } = useEnvironment()
    return env
  }

  get isProduction() {
    const { isProduction } = useEnvironment()
    return isProduction
  }

  mounted() {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
      return
    }

    const { onResult } = useQuery<MeUserQueryQuery>(MeUserQuery)

    onResult((result) => {
      if (result.data?.meUser) {
        this.updateSession({
          user: result.data.meUser,
        })
      }
    })
  }
}
