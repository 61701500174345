
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Testimonial, { IUserTestimonial } from "../../testimonial/Testimonial.vue"
import { Component, Vue, Emit } from "vue-property-decorator"
import WorldConsultantsMap from "../../worldConsultantsMap/WorldConsultantsMap.vue"
import TestimonialsData from "./testimonials_data.json"
import UnAuthPage from "@/components/layout/UnAuthPage.vue"
import { useEnvironment } from "@/hooks/useEnvironment"

interface ISocialMedia {
  name: string
  icon: string
  link: string
}
interface IMidRowIcons {
  name: string
  icon: string
}

interface ITabName {
  title: string
  data: IUserTestimonial[]
}
@Component({
  components: {
    Testimonial,
    WorldConsultantsMap,
    UnAuthPage,
  },
})
export default class LandingPage extends Vue {
  loading = false
  maxTabItems = 3
  hireLink = "https://clients044218.typeform.com/to/JXqYvy?typeform-source=africaforesight.com"

  get isProduction() {
    const { isProduction } = useEnvironment()
    return isProduction
  }

  get aheadApplicationTrackId() {
    return this.isProduction ? "4517239960" : "4517239960"
  }

  socialMedia: ISocialMedia[] = [
    {
      name: "Linkedin",
      icon: "la-linkedin",
      link: "https://www.linkedin.com/company/africa-foresight-group",
    },
    {
      name: "Twitter",
      icon: "la-twitter",
      link: "https://twitter.com/AfricaForesight",
    },
    {
      name: "Instagram",
      icon: "la-instagram",
      link: "https://www.instagram.com/africaforesightgroup/",
    },
  ]
  midRowIcons: IMidRowIcons[] = [
    {
      name: "Due Diligence",
      icon: "la-user-check",
    },
    {
      name: "Implementation",
      icon: "la-cog",
    },
    {
      name: "Research",
      icon: "la-search",
    },
    {
      name: "Stakeholder Engagement",
      icon: "la-users",
    },
    {
      name: "Strategy",
      icon: "la-project-diagram",
    },
  ]

  windowWidth: number | null = null

  tab = null
  clientsFeedBack: IUserTestimonial[] = TestimonialsData.clientsFeedBack

  consultantsFeedBack: IUserTestimonial[] = TestimonialsData.consultantsFeedBack

  tabs: ITabName[] = [
    // { title: "What clients are saying", data: this.clientsFeedBack },
    { title: "What consultants are saying", data: this.consultantsFeedBack },
  ]

  created() {
    this.responsiveActions()
    window.addEventListener("resize", () => this.responsiveActions())
  }

  mounted() {
    this.responsiveActions()
  }

  responsiveActions() {
    this.windowWidth = window.innerWidth

    if (this.windowWidth > 900) {
      this.maxTabItems = 3
    }

    if (this.windowWidth > 800 && this.windowWidth <= 900) {
      this.maxTabItems = 2
    }
    if (this.windowWidth <= 800) {
      this.maxTabItems = 1
    }
  }

  @Emit("change")
  changePage(page: string) {
    return page
  }
}
