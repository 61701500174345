
import { Component, Vue, Prop } from "vue-property-decorator"

export interface IUserTestimonial {
  firstName: string
  lastName: string
  testimony: string
  profilePic: string
  company?: string
  readMoreLink?: string
  country?: string
}

@Component
export default class Testimonial extends Vue {
  @Prop() readonly usertestomial!: IUserTestimonial
}
