import Routes from "@/router/routes"
import { can } from "@/mixins/Authorizer"
import { AuthState } from "@/store/auth"
import { PermissionEnum } from "@/gql"

const useConsultantRoutes = (user: AuthState["user"]): any[] => {
  return [
    {
      path: "/opportunities",
      name: Routes.Opportunities,
      component: () => import("../views/Opportunities.vue"),
      meta: {
        navigatesTo: "Opportunities",
      },
    },
    {
      path: "/my-missions",
      name: Routes.MyMissions,
      component: () => import("../views/MyMissions.vue"),
      meta: {
        navigatesTo: "My Missions",
      },
    },
    {
      path: "/mission-opportunities/:id",
      name: Routes.MissionRoleDetail,
      component: () => import("../views/missions/StaffingPositionDetail.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "/billing-accounts",
      name: Routes.BillingAccounts,
      component: () => import("../views/consultant/ConsultantBillingAccounts.vue"),
      meta: {
        navigatesTo: true,
      },
    },
    {
      path: "/performance-appraisals",
      name: Routes.MyPerformanceAppraisals,
      component: () => import("../views/Appraisals.vue"),
      meta: {
        navigatesTo: "Appraisals",
      },
    },
    {
      path: "/peraltees",
      name: Routes.MyPeraltees,
      component: () => import("../views/consultant/Peraltees.vue"),
      meta: {
        navigatesTo: "Peraltees",
      },
    },
    can(user, [PermissionEnum.ViewScopedMissions]) && {
      path: "missions",
      name: Routes.Missions,
      component: () => import(/* webpackChunkName: "missions" */ "../views/missions/Missions.vue"),
      meta: {
        navigatesTo: "Missions",
      },
    },
    can(user, [PermissionEnum.ViewScopedMissions]) && {
      path: "missions/:id",
      name: Routes.MissionDetail,
      component: () =>
        import(
          /* webpackChunkName: "mission-detail" */ "../views/missions/MissionDetail/MissionDetail.vue"
        ),
      meta: {
        navigatesTo: true,
      },
    },
  ]
}

export default useConsultantRoutes
