
import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator"
import { DocumentNode } from "graphql"
import FilterBar, {
  ActionType,
  ListFilter,
  SearchFilter,
  DateFilter,
  ExportOptions,
  ToggleFilter,
  FilterType,
  NumericFilter,
} from "@/components/widgets/common/FilterBar.vue"

@Component({
  apollo: {
    queryKey: {
      query() {
        return this.query
      },
      variables() {
        return this.queryVars
      },
      result(result) {
        if (result && result.data) {
          this.items = result.data[this.queryKey].data
          this.onQueryResult(result)
        }

        this.loading = false
      },
      update(data) {
        return data[this.queryKey]
      },
    },
  },
})
export default class Table extends Vue {
  @Prop({ required: true })
  readonly query!: DocumentNode

  @Prop({ required: true })
  readonly queryKey!: string

  @Prop({ required: true })
  readonly headers!: { [key: string]: any }[]

  @Prop()
  readonly actions?: ActionType
  @Prop()
  readonly customData?: { [key: string]: any }[]

  @Prop()
  readonly listFilters?: ListFilter[]

  @Prop()
  readonly searchFilters?: SearchFilter[]

  @Prop()
  readonly dateFilters?: DateFilter[]

  @Prop()
  readonly numericFilters?: NumericFilter[]

  @Prop()
  readonly exportOptions?: ExportOptions

  @Prop()
  readonly toggleFilters?: ToggleFilter[]

  @Prop()
  readonly itemActions?: ActionType

  @Prop()
  readonly defaultFilter?: FilterType

  // Syncs with table $table.options
  options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  }

  loading = false
  filter = {}
  sort: { [key: string]: string } = {}
  items = []
  public totalSize = 0

  get queryVars() {
    return {
      filter: this.filter,
      sort: Object.keys(this.sort).length === 0 ? undefined : this.sort,
      page: this.options.page,
      per: this.options.itemsPerPage,
    }
  }

  async refetchQuery() {
    this.$apollo.queries.queryKey.refetch()
  }

  get tableActions() {
    if (this.exportOptions) {
      const exportFn = () => {
        // Set options for export
        const options: ExportOptions = {
          headers: this.exportOptions!.headers,
          query: this.query,
          queryKey: this.queryKey,
          variables: { per: this.totalSize || 1, filter: this.filter },
        }

        this.$refs.filterBar && (this.$refs.filterBar as any).export(options)
      }

      return { ["Export"]: exportFn, ...this.actions }
    } else {
      return this.actions
    }
  }

  async setFilter(key: string, filter: any) {
    this.$refs.filterBar && (await (this.$refs.filterBar as FilterBar).setFilter(key, filter))
  }

  @Watch("options")
  onOptionsChanged() {
    // changes made to options will invoke the query refetch
    const sort: { [key: string]: string } = {}
    for (const i in this.options.sortBy) {
      sort[this.options.sortBy[i]] = this.options.sortDesc[i] ? "desc" : "asc"
    }
    this.sort = sort
  }

  onQueryResult(result: { [key: string]: any }) {
    if (result.data && result.data[this.queryKey]) {
      this.totalSize = result.data[this.queryKey].pagination.totalSize
    }
  }

  @Emit("item:action")
  onItemAction(action: string, item: any) {
    if (this.itemActions && typeof this.itemActions[action] === "function") {
      // Call function with $item as args
      this.itemActions[action].call(this, item)
      return action
    }

    this.addError(`Unknown action: ${action}`)
  }

  @Emit("click:row")
  onRowClick(data: any) {
    return data
  }

  created() {
    this.headers &&
      this.headers.forEach((h) => {
        h["class"] = "subtitle-2 font-weight-bold"
      })
  }
}
