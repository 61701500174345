import moment from "moment"
import { DocumentNode } from "graphql"
import { MutationUpdaterFn, RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { FetchResult } from "apollo-link"
import EventBus from "@/components/EventBus"
import { ErrorV2, ProductSlugEnum } from "@/gql"
import { OperationVariables } from "apollo-client"
import { ref } from "vue"
import { useNotification } from "@/hooks/useNotification"
import useRouter from "@/router/useRouter"
import Routes from "@/router/routes"
import { useMutation } from "@vue/apollo-composable"
import { inject } from "vue"
import { Framework } from "vuetify"

type MutationOptions<
  T extends Record<string, any> = Record<string, any>,
  Q extends OperationVariables = OperationVariables
> = {
  mutation: DocumentNode
  variables?: Q
  refetchQueries?: RefetchQueryDescription
  done?: () => any
  error?: (response: ErrorV2) => any
  success?: (data: T) => void
  update?: MutationUpdaterFn<T>
  handlePayloadErrors?: boolean
}

export const useUtilities = () => {
  function log(...args: any[]): void {
    console.log(args)
  }

  function logWarning(...args: any[]): void {
    console.log(args)
  }

  function logError(...args: any[]): void {
    console.log(args)
  }

  function hasHistory(): boolean {
    return window.history && window.history.length > 2
  }

  function truncate(str: string, length = 50): string {
    return str?.length > length ? str.substring(0, length) + "..." : str
  }

  function formatDate(date: string, format = "Do MMMM YYYY"): string {
    return moment(date).format(format)
  }

  function debounceCall(fn: () => any, ms = 200): void {
    const debounceTimeout = ref<ReturnType<typeof setTimeout>>()

    if (debounceTimeout) clearTimeout(debounceTimeout.value)
    // Set new timeout
    debounceTimeout.value = setTimeout(() => {
      fn()
    }, ms)
  }

  async function mutate<
    T extends Record<string, any> = Record<string, any>,
    Q extends OperationVariables = OperationVariables
  >(options: MutationOptions<T, Q>): Promise<FetchResult<T> | null | undefined> {
    const { addGraphQLError, addMutationError } = useNotification()

    // returns Promise of T

    const { mutate, onError, onDone } = useMutation<T>(options.mutation, {
      variables: options.variables,
      refetchQueries: options.refetchQueries,
    })

    onError((error) => {
      options.handlePayloadErrors && addGraphQLError(error as Error)
    })

    onDone((result) => {
      // Handle mutation errors
      if (!result.data) return

      const mutationKey = Object.keys(result.data)[0],
        errorResponse = (result.data[mutationKey as keyof T] as T[keyof T] & { error: ErrorV2 })
          .error

      if (errorResponse) {
        if (options.error) options.error.call(result, errorResponse)
        else addMutationError(errorResponse)
      } else {
        if (options.success) options.success.call(result, result.data)
      }

      return result
    })

    const result = await mutate()

    options.done?.()

    return result
  }

  function moneyFormat(value: string | number): string {
    return parseFloat(value as string)
      .toFixed(2)
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  function snakeCaseTextFormat(value: string): string {
    return value.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : " " + d.toUpperCase()
    )
  }

  function assignObjectVals(
    from: { [key: string]: any },
    to: { [key: string]: any },
    mapping?: Record<string, string>
  ): void {
    for (const field in to) {
      if (Object.prototype.hasOwnProperty.call(to, field)) {
        const current = from[field]
        if (current) to[field] = current
      }
    }

    // if there is a field mapping
    if (mapping) {
      for (const key in mapping) {
        if (Object.prototype.hasOwnProperty.call(mapping, key)) {
          const current = from[key]
          if (current) to[mapping[key]] = current // mapping[key] returns field name in to
        }
      }
    }
  }

  function generateRand(length: number): string {
    let result = ""
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function refetchMission(): void {
    EventBus.$emit("refetch-missionlifecycle-query")
  }

  function updateCache<QT>(
    cache: Parameters<MutationUpdaterFn>[0],
    query: DocumentNode,
    fn: (data: QT | null) => any,
    queryVars?: Record<string, any>
  ): void {
    try {
      // fetch data from cache
      const cacheData = cache.readQuery<QT>({
        query,
        variables: queryVars,
      })

      // write query to cache with return value of fn
      cache.writeQuery({
        query,
        variables: queryVars,
        data: fn(JSON.parse(JSON.stringify(cacheData))),
      })
    } catch (error) {
      console.error(error)
    }
  }

  function routeBack(route?: string): void {
    const { router } = useRouter()
    hasHistory()
      ? router.back()
      : router.push({
          name: route || Routes.Home, // Keep default as Home when no route is specified.
        })
  }

  function zip(rows: any[][]): any[][] {
    return rows.length ? rows[0].map((_, c) => rows.map((row) => row[c])) : []
  }

  function sanitizeUnderscores(str: string): string {
    return str.replace(/_/g, " ")
  }

  function collapseDrawer(): boolean {
    const { router } = useRouter()
    const vuetifyInstance = inject<Framework>("vuetify")

    return router.currentRoute.meta?.fullScreen || vuetifyInstance?.breakpoint.smAndDown
  }

  function isMobile(): boolean {
    const vuetifyInstance = inject<Framework>("vuetify")
    return !!vuetifyInstance?.breakpoint.smAndDown
  }

  function updateSearchParams({ key, value }: { key: string; value: string }): void {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    searchParams.set(key, value)
    url.search = searchParams.toString()
    window.history.replaceState({}, "", url.toString())
  }

  function deleteSearchParams(key: string): void {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    searchParams.delete(key)
    url.search = searchParams.toString()
    console.log(url.toString())
    window.history.replaceState({}, "", url.toString())
  }

  function getProductFromSlug(value: string): keyof typeof ProductSlugEnum | undefined {
    return Object.keys(ProductSlugEnum).find(
      (key) => ProductSlugEnum[key as keyof typeof ProductSlugEnum] === value
    ) as keyof typeof ProductSlugEnum | undefined
  }

  return {
    log,
    logWarning,
    logError,
    hasHistory,
    truncate,
    formatDate,
    debounceCall,
    mutate,
    moneyFormat,
    snakeCaseTextFormat,
    assignObjectVals,
    generateRand,
    refetchMission,
    updateCache,
    routeBack,
    zip,
    sanitizeUnderscores,
    collapseDrawer,
    isMobile,
    updateSearchParams,
    getProductFromSlug,
    deleteSearchParams,
  }
}
