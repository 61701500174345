
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"

export type PreviewableType = "image" | "video" | "pdf" | "audio" | "document"

enum PreviewableTypeEnum {
  image = "image",
  video = "video",
  pdf = "pdf",
  audio = "audio",
  document = "document",
}

@Component
export default class Previewable extends Vue {
  @Prop({ default: "Preview" }) readonly title!: string
  @Prop() readonly value!: boolean
  @Prop() readonly editable?: boolean
  @Prop() readonly url?: string
  @Prop() readonly type?: PreviewableType

  loadingIframeContent = false
  PreviewableTypeEnum = PreviewableTypeEnum

  @Emit("edit")
  onEdit() {
    return true
  }

  @Emit("input")
  @Emit("close")
  onClose() {
    return false
  }

  @Watch("value")
  onValueChange() {
    if (this.value) {
      // Content of v-dialog not rendered until value changes, nextTick called when content is rendered
      this.$nextTick(() => {
        if (this.$refs.iframe) {
          this.loadingIframeContent = true
          const document = this.$refs.iframe as HTMLIFrameElement

          if (document) {
            document.onload = () => {
              this.loadingIframeContent = false
            }
          }
        }
      })
    }
  }
}
